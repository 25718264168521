<div class="list list-wrapper divider" *ngIf="list">
  <div
    *ngFor="let item of list; trackBy: trackByItems"
    class="list-item"
    [class.is-active]="item.status !== 'soon'"
    (click)="clickLink.emit(item)">
    <div class="item_container">
      <div class="item-label" [ngClass]="item.statusLabelClass">
        <a class="segment u-lmenu" [class.scrapper-language-tag]="item.page === 'language'">
          <i role="img" [ngClass]="'app-icon-' + item.icon" class="segment-icon" *ngIf="item.icon"></i>
          <img [src]="item.imgIcon" class="segment-icon" *ngIf="item.imgIcon" />
        </a>
        <div class="menu-texts">
          <p class="u-p2" [class.has-subtitle]="item.hasNewOptionsWithText">
            {{ item.name | translate }}
          </p>
          <div class="subtitle-container" *ngIf="item.hasNewOptionsWithText">
            <span class="notification-dot"> </span>
            <span>{{ item.newOptionsLabelKey | translate }}</span>
          </div>
        </div>
        <ion-icon
          *ngIf="item.hasSoon"
          class="item-label__tooltip"
          name="information-circle-outline"
          (mouseover)="showPopover($event)"></ion-icon>
      </div>
      <ion-badge *ngIf="item.hasNew" color="completed" class="item_status item_status--{{ item.status }}">
        {{ item.statusBadge | translate }}
      </ion-badge>
      <span *ngIf="item.hasNewOptions" class="notification-dot"> </span>
    </div>
  </div>
</div>
