export enum NewsTypes {
  NEWS = 'NEWS',
  NEWS_MOT = 'NEWS_MOT',
  NEWS_PORTUGAL = 'NEWS_PT',
  NEWS_PTRAB_AND_LEGAL_CONDITIONS = 'NEWS_LC',
  NEWS_PORTUGAL_AND_LEGAL_CONDITIONS = 'NEWS_PT_LC',
  LEGAL_CONDITIONS = 'LC',
  NEWS_GENERAL = 'NEWS_GENERAL',
  NEWS_ORGANIZA2 = 'NEWS_ORGANIZA2',
  NEWS_ORGANIZA2_FEATURE = 'NEWS_ORGANIZA2_FEATURE',
  NEWS_PTRAB_FEATURE = 'NEWS_PTRAB_FEATURE',
  NEWS_MOT_FEATURE = 'NEWS_MOT_FEATURE',
  NEWS_DOCUMENTS = 'PENDING_DOCUMENT',
  NEWS_VACATIONS_EDIT = 'VACATIONS_PROPOSALS_EDIT'
}
