import { IProposal } from './manage-vacations.interface';
/* eslint-disable @typescript-eslint/naming-convention */

export const enum SEASONS {
  winter = 'winter',
  summer = 'summer'
}

export const enum SLOT_DURATION {
  winter_short = 1,
  summer_short = 2,
  long = 3,
  maximum = 4
}

export enum EditVacationsProposalAction {
  ADD,
  EDIT,
  DELETE,
  SAVE_CHANGES
}

export const countDays = (slots: IProposal[]): number => {
  return slots.reduce((selectedSlot, { num_of_days }) => selectedSlot + (num_of_days ?? 0), 0);
};

export const countDuration = (slots: IProposal[]): number => {
  return slots.reduce((selectedSlot, { duration }) => selectedSlot + (duration ?? 0), 0);
};

export const checkEqualSlotsDates = (proposalSlot: IProposal, selectedSlot: IProposal): boolean => {
  return proposalSlot.start_date === selectedSlot.start_date && proposalSlot.end_date === selectedSlot.end_date;
};

export const orderSlotsInProposalByStartDate = (slots: IProposal[]): IProposal[] => {
  return slots.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
};

import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { flatten } from '@app/shared/utils/utils';

const managePermissions: { [key: string]: Permissions[] } = {
  MANAGE_VACATIONS: [
    Permissions.MOT_VACATIONS_PROPOSAL_LIST,
    Permissions.MOT_VACATIONS_PROPOSAL_EDIT,
    Permissions.MOMO_VACATIONS_PROPOSAL_LIST,
    Permissions.MOMO_VACATIONS_PROPOSAL_EDIT
  ]
};

export const MANAGE_VACATIONS_PERMISSIONS = flatten(Object.values(managePermissions));
