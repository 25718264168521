import { Component, Input } from '@angular/core';

import { ITitleDescription } from '@app/activo2/manage-vacations/manage-vacations.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-title-description-multiple-card',
  templateUrl: './title-description-multiple-card.component.html',
  styleUrls: ['./title-description-multiple-card.component.scss']
})
export class TitleDescriptionMultipleCardComponent {
  @Input() values!: ITitleDescription[];
  @Input() cardTitle!: string;
  @Input() insideModal!: boolean;
  @Input() listStyle!: boolean;
  @Input() hasAction = false;

  trackByItems(item: MSafeAny) {
    return item;
  }
}
